export default 
{
    "order_colors": [
        "green", 
        "yellow"
    ],
    "order_status": [
        "Order_Received",
        "Order_in_Progress",
        "Ready_for_Pickup",
        "Picked_Up"
    ]
}