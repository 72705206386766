import { Component, OnInit } from '@angular/core';
import { Payment } from '../payment';
import { ActivatedRoute, Router } from '@angular/router';
//import { PaymentService } from '../payment.service';
//import { FormGroup } from '@angular/forms';
import { render } from 'creditcardpayments/creditCardPayments';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.css']
})
export class CardDetailsComponent implements OnInit {

  payment: Payment;
  //paymentForm: FormGroup;

  //private paymentService: PaymentService, private router: Router, private route: ActivatedRoute
  constructor() { 
    render (
      {
        id: "#payments",
        currency: "USD",
        value: "10.00",
        onApprove: (details) => {
          alert("Transaction Successful!");
        }
      }
    );
  }

  ngOnInit() {
    
  }

}
