import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Product } from './product';
import { FileUploadService } from './file-upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const baseUrl = environment.modules.auth.basic.baseUrl; //'http://localhost:4201';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) {
  }

  private async request(method: string, url: string, data?: any, file?:any, responseType?: any) {
    const token = "sdsfdsfsdfdswetwtfs" //await this.oktaAuth.getAccessToken();

    console.log('url: ' + url);
    const result = this.http.request(method, url, {
      body: data,
      responseType: responseType || 'json',
      observe: 'body',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return new Promise<any>((resolve, reject) => {
      result.subscribe(resolve as any, reject as any);
    });
  } 

  getProducts() {
    return this.request('get', `${baseUrl}/products`);
  }

  /**
   * retreive merchantId
   * @returns 
   */
  getMerchantId() {
    return this.request('get', `${baseUrl}/merchantId`);
  }

  getProduct(id: string) {
    return this.request('get', `${baseUrl}/product/${id}`);
  }

  createProduct(product: Product, image?: File) {
  
    product.image = image;

    console.log('--createProduct ' + JSON.stringify(product));
    console.log('product.image ' + product.image)
    return this.request('post', `${baseUrl}/product`, product, product.image);
  }

  uploadImage(file: File, product: Product){
    console.log('--uploadImage--')
    const formData: FormData = new FormData();

    formData.append('image', file, `${product.sku}.jpeg`);
    console.log(" --formData = " + formData);

    const req = new HttpRequest('POST', `${baseUrl}/uploads`, formData);
  }

   /*  const req = new HttpRequest('POST', `${baseUrl}/uploads`, file, {
      //reportProgress: true,
      responseType: 'json'
    }); */

    /*   
     const req = new HttpRequest('POST', `${baseUrl}/uploads`, formData, {
      //reportProgress: true,
      responseType: 'json'
    }); 
    */

    //return this.http.request(req);
    
  //}
  
  updateProduct(product: Product,  image: File) {
    //this.uploadImage(product.image, product);
    product.image = image;

    console.log('--updateProduct-- ' + JSON.stringify(product));
    return this.request('post', `${baseUrl}/product/${product.id}`, product);
  }

  deleteProduct(id: string) {
    
    return this.request('delete', `${baseUrl}/product/${id}`, null, 'text');
  }
}
