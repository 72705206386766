import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Order, OrderOms } from '../order';
import { OrderService } from '../order.service';
import config from '../../assets/config/default'

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css'],
})


export class OrderDetailsComponent implements OnInit {

  public orderOms: OrderOms; 
  public qty: number;
  public merchantId: string;
  public statusChange = false;
  public confirmedSelectedStatus: string;
  public rawOrder: any;

  public orderStatuses:Array<Object> = [
    {id: 1, status: config.order_status[0]},
    {id: 2, status: config.order_status[1]},
    {id: 3, status: config.order_status[2]},
    {id: 4, status: config.order_status[3]}
  ]

  selectedStatus;
  constructor(private _orderService: OrderService, private _router: Router, private _route: ActivatedRoute) { }

  ngOnInit() {
    if(localStorage.getItem("Order")) {
      this.orderOms = JSON.parse(localStorage.getItem("Order")); 
      let counter = 0;
      console.log(this.orderOms.products); 
      if(this.orderOms.products) {
        for(let i in this.orderOms.products) {
          counter += this.orderOms.products[i].qty;
        }
      }
      this.qty = counter;  
    }
  }

  selected() {
    this.confirmedSelectedStatus = this.selectedStatus.status;
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  confirmChange() {
    this.orderOms.order_status = this.confirmedSelectedStatus;
      if(this.confirmedSelectedStatus === config.order_status[0]) {
        for(let i = 0; i < this.orderOms.products.length; i++) 
          this.orderOms.products[i].status = 1;
      }
      else if(this.confirmedSelectedStatus === config.order_status[1]) {
        for(let i = 0; i < this.orderOms.products.length; i++) 
          this.orderOms.products[i].status = 2;
      }
      else if(this.confirmedSelectedStatus === config.order_status[2]) {
        for(let i = 0; i < this.orderOms.products.length; i++) 
          this.orderOms.products[i].status = 3;
      } else {
        for(let i = 0; i < this.orderOms.products.length; i++) 
          this.orderOms.products[i].status = 4;
      }
    
    localStorage.setItem("Order", JSON.stringify(this.orderOms));

    if(this.orderOms.order_type !== "merchant") {
      this.orderOms.order_type = "customer";
    }
    
    let order: Order = {
      order_instructions: this.orderOms.order_instructions,
      order_name: this.orderOms.order_name,
      order_type: this.orderOms.order_type,
      merchant_id: this.orderOms.merchant_id,
      cart_id: this.orderOms.cart_id,
      user_id: this.orderOms.user_id,
      device_id: this.orderOms.device_id,
      products: this.orderOms.products,
      addressInformation: this.orderOms.addressInformation,
      is_created: false,
      orderIncrementId: this.orderOms.orderIncrementId, 
      magentoOrderId: this.orderOms.magentoOrderId, 
      transferredAt: this.orderOms.transferredAt, 
      created_at: this.orderOms.created_at,
    }
    
    this._orderService.updateOrder(order).subscribe(response => console.log("Get back from backend" + JSON.stringify(response))); 
  }

  deleteOrder() {
    this._orderService.deleteOrder(parseInt(this.orderOms[0].cart_id)).subscribe(() => this._router.navigate(['/order-list/true']));
  }
}
