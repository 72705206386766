/* import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  /*
  toggleNav() {

} */

import { Component, OnInit } from '@angular/core';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent  implements OnInit  {

  public isDisplayed = false;
  public restaurantLogoPath: string;
  public testimony: string;
  private restaurantInfo = {
    name: '',
    address: '',
    merchantId: ''
  };

  constructor() { }

  ngOnInit() {
    if(localStorage.getItem("merchant_id") != null) {
      this.isDisplayed = true;
    }

   let restaurantLogo = document.getElementById('restaurantLogo') as HTMLImageElement;
    if(localStorage.getItem("merchant_id") === "\"pZfl5DmzLjhiuvDQ+M7Ijqb+lpiwLU+YIrShBNrMJbI=\"") {  
      restaurantLogo.src = '../assets/images/dinos_restaurant.jpeg';
    } else if(localStorage.getItem("merchant_id") === "\"12bH7YRfFQIKPxzULnne06mDd6MfvtY0ePOPgURG72E=\"") { 
      restaurantLogo.src = '../assets/images/california_steak_and_fries.jpg'
    } else if(localStorage.getItem("merchant_id") === "\"Burger Bitch\"") { //update merchant id
      restaurantLogo.src = '../assets/images/burgerbitch.jpeg'
    } 
  }

  onSubmit(data) {
    this.restaurantInfo.name = data.name;
    this.restaurantInfo.address = data.address;
    this.restaurantInfo.merchantId = Base64.stringify(sha256(this.filterAddress(this.restaurantInfo.address)))

    if(data.name && data.address) {
      localStorage.setItem("name", JSON.stringify(this.restaurantInfo.name));
      localStorage.setItem("address", JSON.stringify(this.restaurantInfo.address));
      localStorage.setItem("merchant_id", JSON.stringify(this.restaurantInfo.merchantId));
      this.isDisplayed = true;
    }

  } 

  filterAddress(unfilteredAddress) {
    return unfilteredAddress.replace(/\s,/g, '-').replace(/,\s/g, '-').replace(/\s/g,'-').replace(/,/g,'-').toLowerCase();
  }
}

/* 
    if(document.getElementById("sidenav").style.visibility == "visible")
    {
      document.getElementById("sidenav").style.visibility = "hidden";
      document.getElementById("home").style.marginLeft = "0px";
    }
    else
    {
      document.getElementById("sidenav").style.visibility = "visible";
      document.getElementById("home").style.marginLeft = "200px";
    }
  } 
  
} */



