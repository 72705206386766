import { Component, OnInit } from '@angular/core';
//import { OktaAuthService } from '@okta/okta-angular';
import { environment } from "../environments/environment";
import { AuthService } from './auth/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public title = 'Merchant Order Management';
  public isAuthenticated: boolean = true;

  public isAuthenticatedP: boolean = false;
  public auth  = environment.modules;
  public loggedin: boolean;
  //private subscription: Subscription;
  private $loggedin: Observable<boolean>;
  public isAuthorized: boolean;

  constructor(private providerAuth: AuthService) {
    this.providerAuth.isAuthenticated().subscribe(
      (isAuthenticatedP: boolean) => this.isAuthenticatedP = isAuthenticatedP
      //subscribe(course => this.course = course);
      //const { auth } = environment.modules;
    ); 
    //this.getLoggedInValue().subscribe()
  }

  /* getisAuthenticated(): boolean {
    //throw new Error('Method not implemented.');
    return this.isAuthenticated;

  } */

  ngOnInit() {
   // this.providerAuth.isAuthenticated().subscribe(
   //   (isAuthenticatedP: boolean) => this.isAuthenticatedP = isAuthenticatedP);
    //this.isAuthenticated = false;
    //this.isAuthenticated = true;//await this.oktaAuth.isAuthenticated();
    //this.loggedin = await this.providerAuth.$isLoggedinSource.toPromise();

    this.providerAuth.cast.subscribe(data => this.isAuthorized = data);
    console.log("isAuthorized "+this.isAuthorized);
  }

  async login() {
    //this.auth.auth.basic.signInUrl;
    //await this.oktaAuth.signInWithRedirect();
  }

  async logout() {
    //await this.oktaAuth.signOut();
   // this.isAuthenticated = false;
  } 
  
  /* 
  public setisAuthenticated(isAuth) {
    this.isAuthenticated = isAuth
  }
  */

  /*
  getLoggedInValue() {
  this.$loggedin.subscribe((data: boolean) => 
  {
    console.log('am i logged in ', data);
    return data;
  }) 
  }
  */
}
