import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthModule } from './auth/auth.module';
import {
  MatButtonModule,
  MatDividerModule,
  MatIconModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatToolbarModule
} from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductsComponent } from './products/products.component';
import { HomeComponent } from './home/home.component';
import { CardDetailsComponent } from './card-details/card-details.component';
//import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrdersComponent } from './orders/orders.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SignInComponent } from './auth/components/sign-in/sign-in.component';
import { AuthService } from './auth/services/auth.service';
import { OrderService } from './order.service';
import { UiModule } from './ui/ui.module';
import { AppService } from './shared/services/app.service';
import { CreateUpdateOrderComponent } from './create-update-order/create-update-order.component';
import { FooterComponent } from './footer/footer.component';
//import { MatPaginatorModule } from '@angular/material';

/* const oktaConfig = {
  issuer: 'https://dev-9323263.okta.com/oauth2/default',
  clientId: '0oako0qslkbNKA2kg5d6',
  //redirectUri: window.location.origin + '/callback'
};
 */
@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    HomeComponent,
    CardDetailsComponent,
    AllOrdersComponent,
    OrdersComponent,
    SidenavComponent,
    CreateUpdateOrderComponent,
    OrderDetailsComponent,
    CreateUpdateOrderComponent,
    SignInComponent,
    FooterComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    UiModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    FormsModule,
    //OktaAuthModule,
    //NgxPaginationModule,
    MatPaginatorModule
  ],
  //providers: [
    //{ provide: OKTA_CONFIG, useValue: oktaConfig },
   // AuthService
  //],
  providers: [OrderService, UiModule, AppService, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
