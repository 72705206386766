export class Product {
  id?: number;
  name: string;
  sku: string;
  description: string;
  price: number;
  stock: number;
  relatedSku: string;
  addons: string;
  size: string;
  color: string;
  additionalNotes: string;
  //imageName: string;
  image: File;
  merchantId: string;
  merchantName: string;
}

