const javabaseUrl = 'http://localhost:8080/api/v1';
const baseUrl = 'http://localhost:4201';

export const environment = {
  enabled: true,
  production: false,
  modules: {
    auth: {
      successRedirectUrl: '/home',
      enabled: true,
      keys: {
        access_token: 'usr_access_token',
        token_type: 'token_type',
        local_storage_access_token: 'access_token'
      },
      basic: {
        signInUrl: `${javabaseUrl}/login/`,
        baseUrl: `${baseUrl}`,
        enabled: true, 
        fields: {
          email: 'username',
          password: 'password'
        }
      }
    },
    shared: {
      predictAssetsUrl: `${javabaseUrl}/predictassets`,
      detectOutlierUrl: `${javabaseUrl}/detectoutlier`,
      recommendAssetsUrl: `${javabaseUrl}/recommendassets`
    }
  },
  firebaseConfig: {
    apiKey: "AIzaSyBx2wMzobCvPkedP6GBEAK8dbDZMj-rg7k",
    authDomain: "fir-demo-cf1b4.firebaseapp.com",
    databaseURL: "https://fir-demo-cf1b4-default-rtdb.firebaseio.com",
    projectId: "fir-demo-cf1b4",
    storageBucket: "fir-demo-cf1b4.appspot.com",
    messagingSenderId: "1049633129615",
    appId: "1:1049633129615:web:c1dff232f17ae597c49cfa",
    measurementId: "G-EXHDMJVMGF" 
  }
};





















/* const baseUrl = 'http://localhost:8080/api/v1';

export const environment = {
  enabled: true,
  production: false,
  modules: {
    auth: {
      successRedirectUrl: '/home',
      enabled: true,
      keys: {
        access_token: 'usr_access_token',
        token_type: 'token_type',
        local_storage_access_token: 'access_token'
      },
      basic: {
        signInUrl: `${baseUrl}/login/`,
        enabled: true, 
        fields: {
          email: 'username',
          password: 'password'
        }
      }
    },
    shared: {
      predictAssetsUrl: `${baseUrl}/predictassets`,
      detectOutlierUrl: `${baseUrl}/detectoutlier`,
      recommendAssetsUrl: `${baseUrl}/recommendassets`
    }
  },
  firebaseConfig: {
    apiKey: "AIzaSyBx2wMzobCvPkedP6GBEAK8dbDZMj-rg7k",
    authDomain: "fir-demo-cf1b4.firebaseapp.com",
    databaseURL: "https://fir-demo-cf1b4-default-rtdb.firebaseio.com",
    projectId: "fir-demo-cf1b4",
    storageBucket: "fir-demo-cf1b4.appspot.com",
    messagingSenderId: "1049633129615",
    appId: "1:1049633129615:web:c1dff232f17ae597c49cfa",
    measurementId: "G-EXHDMJVMGF" 
  }
}; */
