import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../order.service';
import { Order, OrderOms } from '../order';
//import { OrderData } from '../order-data';
import {elementAt, map} from 'rxjs/operators';
import config  from '../../assets/config/default' 
import { Observable, Subject, timer } from 'rxjs';
import { switchMap }  from 'rxjs/operators';

//import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.css']
})

export class AllOrdersComponent implements OnInit {

  public shouldModifyOrder: boolean;

  public ordersOms: Map<string, OrderOms> = new Map();
  public orderStatuses:Array<string> = config.order_status;
  public orderColors:Array<string> = config.order_colors;

  public ordersRaw: any = [];
  private stopPolling = new Subject();

  constructor(private route: ActivatedRoute, private router: Router, private _orderService: OrderService) { }

  ngOnInit() {
      this.shouldModifyOrder = this.route.snapshot.paramMap.get('modifiable') != null ? true : false;
      if(localStorage.getItem('merchant_id')) {
        timer(0, 8000).pipe(
          switchMap(() => this._orderService.getOrdersByMerchant('pZfl5DmzLjhiuvDQ+M7Ijqb+lpiwLU+YIrShBNrMJbI='/*localStorage.getItem('merchant_id').replace(/\"/g, '')*/))
          //switchMap(() => this._orderService.getOrdersByMerchant(localStorage.getItem('merchant_id').replace(/\"/g, '')))
        ).subscribe(response => {
          for(const d of (response as any)) {
            this.ordersRaw.push({
                id: d.id,
                data: d.data
            });
          }
          // for(let i in this.ordersRaw) { // loops through all orders
          //   for(let k in this.ordersRaw[i].data.orders) { // always loops through 1 order. need for loop to reference key index 
          //     this._orderService.orders.set(this.ordersRaw[i].data.orders[k].order.cart_id, this.ordersRaw[i].data.orders[k]);
          //     this._orderService.orders.get(this.ordersRaw[i].data.orders[k].order.cart_id).order.time_created = (new Date(this.ordersRaw[i].data.orders[k].updated_at)).toLocaleString("en-US", {timeZone: "America/Los_Angeles"}) + " Pacific Time";
          //     this._orderService.orders.get(this.ordersRaw[i].data.orders[k].order.cart_id).order.time_updated = (new Date(this.ordersRaw[i].data.updated_at)).toLocaleString("en-US", {timeZone: "America/Los_Angeles"}) + " Pacific Time";
          //     this._orderService.orders.get(this.ordersRaw[i].data.orders[k].order.cart_id).merchant_id = this.ordersRaw[0].data.merchant_id;
          //     this._orderService.orders.get(this.ordersRaw[i].data.orders[k].order.cart_id).order.total_price = 0;  
          //     for(let j in this.ordersRaw[i].data.orders[k].order.products) {
          //       this._orderService.orders.get(this.ordersRaw[i].data.orders[k].order.cart_id).order.total_price += 
          //       this._orderService.orders.get(this.ordersRaw[i].data.orders[k].order.cart_id).order.products[j].totals.price_incl_tax;
          //     }
          //   }
            for(let i in this.ordersRaw) { // loops through all orders
              for(let k in this.ordersRaw[i].data.orders) { // always loops through 1 order. need for loop to reference key index 
                this._orderService.ordersOms.set(this.ordersRaw[i].data.orders[k].cart_id, this.ordersRaw[i].data.orders[k]); Intl.DateTimeFormat().resolvedOptions().timeZone;
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).time_created = (new Date(this.ordersRaw[i].data.orders[k].created_at)).toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}) + ' ' + new Date().toLocaleTimeString('en-US',{timeZoneName:'short'}).split(' ')[2];
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).time_updated = (new Date(this.ordersRaw[i].data.updated_at)).toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}) + ' ' + new Date().toLocaleTimeString('en-US',{timeZoneName:'short'}).split(' ')[2];
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).merchant_id = this.ordersRaw[i].data.merchant_id;
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).total_price = 0;  
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).order_status = this.orderStatuses[this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).products[0].status - 1];
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).orderIncrementId = this.ordersRaw[i].data.orders[k].orderIncrementId;
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).magentoOrderId = this.ordersRaw[i].data.orders[k].magentoOrderId;
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).transferredAt = this.ordersRaw[i].data.orders[k].transferredAt;
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).created_at = this.ordersRaw[i].data.orders[k].created_at;
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).is_created = this.ordersRaw[i].data.orders[k].is_created; 
                console.log("All products: " + JSON.stringify(this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).products))

                for(let j in this.ordersRaw[i].data.orders[k].products) {
                  //console.log('Order: ' + this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).products[j]);
                  this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).products[j].parentSku = this.ordersRaw[i].data.orders[k].products[0].parentSku;
                  this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).total_price += 
                  this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).products[j].totals.price_incl_tax;
                  this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).products[j].size = this.ordersRaw[i].data.orders[k].products[0].size;
                  this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).products[j].color = this.ordersRaw[i].data.orders[k].products[0].color;
                }
                this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).total_price = parseFloat(this._orderService.ordersOms.get(this.ordersRaw[i].data.orders[k].cart_id).total_price.toFixed(2))

              }
              
          }
        

          let audio = new Audio();
          audio.src = "../../assets/notification.mp3";
      
          this.ordersOms = this._orderService.ordersOms;

          if(localStorage.getItem("num_orders")) {
            if(parseInt(localStorage.getItem("num_orders")) < this.ordersOms.size) {
              localStorage.setItem("num_orders", JSON.stringify(this.ordersOms.size));
              audio.play();
            } else {
              localStorage.setItem("num_orders", JSON.stringify(this.ordersOms.size)); 
            }
          } else {
            localStorage.setItem("num_orders", JSON.stringify(this.ordersOms.size));
          }
    
        });  
      }
  }   

  deleteOrder(id: number) {
    this._orderService.deleteOrder(id).subscribe(() => this.ngOnInit());
  }

  getValues(ordersOms) {
    if(!ordersOms) return [];
    console.log('Order OMS Values: ' + JSON.stringify(ordersOms.values()[0]))
    return Array.from(ordersOms.values());
  }

  setOrderDetailOrder(cart_id: string) {
    localStorage.setItem("Order", JSON.stringify(this.ordersOms.get(cart_id)));
  }

} 


