import { Component, OnInit } from '@angular/core';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../order.service';
import { Order, Product, ItemDetail } from '../order'
import { Md5 } from 'ts-md5/dist/md5';
import axios from "axios";
import { MockSchemaRegistry } from '@angular/compiler/testing';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-update-order.component.html',
  styleUrls: ['./create-update-order.component.css']
})
export class CreateUpdateOrderComponent implements OnInit {

  public order: Order; 
  public orderForItemDisplay: Order;
  public productDetail: Product;
  public items: Map<string, ItemDetail>; //= new Map(); 
  public special_instructions_list: Map<string, number> = new Map(); 
  public top_ten_special_instructions = []
  public all_items = [];

  constructor(private route: ActivatedRoute, private router: Router, private _orderService: OrderService) { }
  
  ngOnInit() {
    if(localStorage.getItem("special_instructions")) { 
      let allInstructions = JSON.parse(localStorage.getItem("special_instructions"));
      for(let i = 0; i < 10; i++) { //top 10 
        if(allInstructions[i])
          this.top_ten_special_instructions.push(allInstructions[i][0])
      }

      for(let i = 0; i < allInstructions.length; i++) { //save all isntructions as top 10 can change
        this.special_instructions_list.set(allInstructions[i][0], allInstructions[i][1])
      }
    }
    this.items = new Map();
    this.productDetail = {} as Product;
    this.order = {} as Order;
    this.orderForItemDisplay = {} as Order;
    this._orderService.getItems("california-steaks-and-fries-restaurant").subscribe(response => {

      // LOGGING ITEMS GETTING FROM WWW.SHOPONOMIK.COM/API/V1/...
      console.log("items: " + JSON.stringify(response.results.hits));
      for(let i = 0; i < response.results.hits.length; i++) {
        // storing all the items in a public array
        this.all_items.push(response.results.hits[i]);
        if(!response.results.hits[i]._source.price) {
          axios.post('https://wwww.shoponomik.com/eventlog', {
            "server-mcc": { 
              "errorMsg": "missing price for " + response.results.hits[i]._source.name + "." 
            },
              "eventType": "mcc-error-product-price",
              "uuid": Md5.hashStr(response.results.hits[i]._source.name + Date.now())
            },
            { headers: {'Content-Type': 'application/json'}
          })  
          .then(function (response) {
            console.log('The response is: ' + response);
          })
          .catch(function (error) {
            console.log(error);
          });
        }
        else if(!response.results.hits[i]._source.sku) {
          axios.post('https://www.shoponomik.com/eventlog', {
            "server-mcc": { 
              "errorMsg": "missing sku for " + response.results.hits[i]._source.name + "." 
            },
              "eventType": "mcc-error-product-sku",
              "uuid": Md5.hashStr(response.results.hits[i]._source.name + Date.now())
            },
            { headers: {'Content-Type': 'application/json'}
          })  
          .then(function (response) {
            console.log('The response is: ' + response);
          })
          .catch(function (error) {
            console.log(error);
          });
        } 
        else {
          this.items.set(response.results.hits[i]._source.name,
          {
            name: response.results.hits[i]._source.name, 
            price: response.results.hits[i]._source.price,
            sku: response.results.hits[i]._source.sku,
            tax: response.results.hits[i]._source.tax
          });
        }
      } 
    });

  }

  deleteItem(item): void {
    for(let i = 0; i < this.orderForItemDisplay.products.length; i++) {
      if(item === this.orderForItemDisplay.products[i]) {
        this.orderForItemDisplay.products[i] = {name: "", qty: 0, size:"", color:"", totals: {price_incl_tax: 0}, status: 0, parentSku: "", address: "", special_instructions: ""};
      }
    }
  }

  getItem(item) : void {
    
  }
  
  addProduct(): void {
    if(!this.special_instructions_list.get(this.productDetail.special_instructions)) {
      this.special_instructions_list.set(this.productDetail.special_instructions, 1)
    } else {
      this.special_instructions_list.set(this.productDetail.special_instructions, this.special_instructions_list.get(this.productDetail.special_instructions) + 1)
    }

    const sortedInstructions = new Map([...this.special_instructions_list.entries()].sort((a, b) => b[1] - a[1]));

    localStorage.setItem("special_instructions", JSON.stringify(Array.from(sortedInstructions.entries())));

    if(!this.orderForItemDisplay.products) {
      //console.log('SA--this.productDetail.item --' + JSON.stringify(this.productDetail.item) )  //price = ' + this.items.get(this.productDetail.item).price)
      //console.log('SA--item --' + JSON.stringify(this.productDetail.item) + '  sku = ' + this.items.get(this.productDetail.item).sku)
      //console.log('SA--item --' + this.productDetail.item + ' price=' + this.productDetail.item.price)
      console.log('--SA--this.getItems.length = ' + this.getItems.length)
      console.log('--SA--this.productDetail.item: ' + this.productDetail.item + '\n type: ' + typeof(this.productDetail.item))
      console.log('getItems function with productDetail as parameter ' + this.getItems); 
      console.log('--SA--this.productDetail.quantity: ' + this.productDetail.quantity);
      console.log('Printing a sample item from all_items array: ' + JSON.stringify(this.all_items[1]));
      console.log((this.items.get(this.productDetail.item)));  //Map.get(string)
      console.log('--SA -- name + price' + (this.items.get(this.productDetail.item)).name + ' ' + (this.items.get(this.productDetail.item)).price);
      console.log('--SA--this.orderForItemDisplay.products: ' + this.orderForItemDisplay.products);
      this.orderForItemDisplay.products = [
        {
          name: this.productDetail.item, //this.productDetail.item.substr(0, this.productDetail.item.indexOf('---')),
          //price: this.productDetail.
          qty: this.productDetail.quantity,
          size: this.productDetail.size,
          color: this.productDetail.color,
          totals: {
            price_incl_tax: this.items.get(this.productDetail.item).price * this.productDetail.quantity + this.items.get(this.productDetail.item).price * (this.items.get(this.productDetail.item).tax/100)
            //price_incl_tax: parseFloat((this.productDetail.quantity * (parseFloat((this.productDetail.item.substr(this.productDetail.item.indexOf("---") + 3)))
              //price_incl_tax: parseFloat((this.productDetail.quantity * (parseFloat((this.productDetail.item.substr(this.productDetail.item.indexOf("---") + 3)))
             // + (((this.items.get(this.productDetail.item.substr(0, this.productDetail.item.indexOf('---'))).tax) / 100)
             // * parseFloat((this.productDetail.item.substr(this.productDetail.item.indexOf("---") + 3)))))).toFixed(2))
          },
          status: 1,
          parentSku: this.items.get(this.productDetail.item).sku,
          //parentSku: this.items.get(this.productDetail.item.substr(0, this.productDetail.item.indexOf('---'))).sku,
          address: localStorage.getItem("address").replace(/\"/g,''),
          special_instructions: this.items.get(this.productDetail.item).name + this.items.get(this.productDetail.item).sku + '(' + this.productDetail.special_instructions + ')'
        }
      ];
    } else {
      ///console.log('SA--this.productDetail.item --' + JSON.stringify(this.productDetail.item) + '  price = ' + this.items.get(this.productDetail.item).price)
      //console.log('SA--item --' + JSON.stringify(this.productDetail.item) + '  sku = ' + this.items.get(this.productDetail.item).sku)
      //console.log('SA--item --' + this.productDetail.item.name + ' price=' + this.productDetail.)
      //console.log('--SA--this.getItems.length = ' + this.getItems.length)
      this.orderForItemDisplay.products.push({ 
        name: this.productDetail.item.substr(0, this.productDetail.item.indexOf('---')),
        qty: this.productDetail.quantity,
        size: this.productDetail.size,
        color: this.productDetail.color,
        //price: this.productDetail.
        totals: {
            price_incl_tax: parseFloat((this.productDetail.quantity * (parseFloat((this.productDetail.item.substr(this.productDetail.item.indexOf("---") + 3)))
            + (((this.items.get(this.productDetail.item.substr(0, this.productDetail.item.indexOf('---'))).tax) / 100)
            * parseFloat((this.productDetail.item.substr(this.productDetail.item.indexOf("---") + 3)))))).toFixed(2))
        },
        status: 1,
        parentSku: this.items.get(this.productDetail.item.substr(0, this.productDetail.item.indexOf('---'))).sku,
        address: localStorage.getItem("address").replace(/\"/g,''),
        special_instructions: this.productDetail.item.substr(0, this.productDetail.item.indexOf('---')) + '(' + this.productDetail.special_instructions + ')'
      });
    }
  }

  getItems(items) {
    return Array.from(items.values()); 
  }

  createUpdateOrder(): void {
    this.order.order_type = 'merchant';
  
    for(let i = 0; i < this.orderForItemDisplay.products.length; i++) {
      if(this.orderForItemDisplay.products[i].name !== '') {
        if(!this.order.products) {
          this.order.products = [ this.orderForItemDisplay.products[i] ];
        } else {
          this.order.products.push(this.orderForItemDisplay.products[i]);
        }
      }
    }
    this.order.addressInformation = {
      shippingAddress: {
        country_id: '',
        postcode: '',
        city: '',
        region: '',
        street: ['', '']
      },
      billingAddress: { 
        email: this.productDetail.email,
        telephone: this.productDetail.phone,
        lastname: '',
        firstname: this.productDetail.name,
        country_id: '',
        postcode: '',
        city: '',
        region: '',
        street: ['', '']
      }
    }

    this.order.is_created = true;
    this.order.orderIncrementId = '1';
    this.order.magentoOrderId = '1';
    this.order.transferredAt = '1'; 
    this.order.created_at = Date.now(); 
    this.order.merchant_id = localStorage.getItem("merchant_id").replace(/\"/g,'');
    this.order.cart_id = Md5.hashStr((this.order.addressInformation.billingAddress.telephone + Date.now()).toString()).slice(0, 9);
    this.order.device_id = '1';
    this.order.user_id = '1';

     for(let i = 0; i < this.order.products.length; i++) {
      axios.post('https://www.shoponomik.com/eventlog', {
        "order_creation": { 
          "item_name": this.order.products[i].name,
          //"item_price": (this.items.get(this.order.products[i].totals.price_incl_tax)).toString(),
          "special_instruction": this.order.products[i].special_instructions,
          "merchant_id": localStorage.getItem("merchant_id"), 
          "merchant_address": localStorage.getItem("address"),
          "product_sku": this.order.products[i].parentSku //this.items.get(this.order.products[i].name).sku
        },
          "eventType": "mcc-special-instructions",
          "uuid": Md5.hashStr(this.items.get(this.order.products[i].name).sku + this.order.products[i].special_instructions + Date.now())
          //"uuid": Md5.hashStr(this.items.get(this.order.products[i].name).sku + this.order.products[i].special_instructions + Date.now())
        },
        { headers: {'Content-Type': 'application/json'}
      });  
    }
    this._orderService.updateOrder(this.order).subscribe(
    () => this.router.navigate(['/order-list/true']))
  }
}
























/* import { Component, OnInit } from '@angular/core';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../order.service';
import { Order, Product, ItemDetail } from '../order'
import { Md5 } from 'ts-md5/dist/md5';
import axios from "axios";
import { MockSchemaRegistry } from '@angular/compiler/testing';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-update-order.component.html',
  styleUrls: ['./create-update-order.component.css']
})
export class CreateUpdateOrderComponent implements OnInit {

  public order: Order; 
  public orderForItemDisplay: Order;
  public productDetail: Product;
  public items: Map<string, ItemDetail> = new Map(); 
  public special_instructions_list: Map<string, number> = new Map(); 
  public top_ten_special_instructions = []

  constructor(private route: ActivatedRoute, private router: Router, private _orderService: OrderService) { }
  
  ngOnInit() {
    if(localStorage.getItem("special_instructions")) { 
      let allInstructions = JSON.parse(localStorage.getItem("special_instructions"));
      for(let i = 0; i < 10; i++) { //top 10 
        if(allInstructions[i])
          this.top_ten_special_instructions.push(allInstructions[i][0])
      }

      for(let i = 0; i < allInstructions.length; i++) { //save all isntructions as top 10 can change
        this.special_instructions_list.set(allInstructions[i][0], allInstructions[i][1])
      }
    }

    this.productDetail = {} as Product;
    this.order = {} as Order;
    this.orderForItemDisplay = {} as Order;
    this._orderService.getItems("Banadir-restaurant").subscribe(response => {
      console.log("items: " + JSON.stringify(response.results.hits));
      for(let i = 0; i < response.results.hits.length; i++) {
        if(!response.results.hits[i]._source.price) {
          axios.post('https://beta02.shoponomik.com/eventlog', {
            "server-mcc": { 
              "errorMsg": "missing price for " + response.results.hits[i]._source.name + "." 
            },
              "eventType": "mcc-error-product-price",
              "uuid": Md5.hashStr(response.results.hits[i]._source.name + Date.now())
            },
            { headers: {'Content-Type': 'application/json'}
          })  
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        }
        else if(!response.results.hits[i]._source.sku) {
          axios.post('https://beta02.shoponomik.com/eventlog', {
            "server-mcc": { 
              "errorMsg": "missing sku for " + response.results.hits[i]._source.name + "." 
            },
              "eventType": "mcc-error-product-sku",
              "uuid": Md5.hashStr(response.results.hits[i]._source.name + Date.now())
            },
            { headers: {'Content-Type': 'application/json'}
          })  
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        } 
        else {
          this.items.set(response.results.hits[i]._source.name,
          {
            name: response.results.hits[i]._source.name, 
            price: response.results.hits[i]._source.price,
            sku: response.results.hits[i]._source.sku,
            tax: response.results.hits[i]._source.tax
          });
        }
      } 
    });

  }

  deleteItem(item): void {
    for(let i = 0; i < this.orderForItemDisplay.products.length; i++) {
      if(item === this.orderForItemDisplay.products[i]) {
        this.orderForItemDisplay.products[i] = {name: "", qty: 0, totals: {price_incl_tax: 0}, status: 0, parentSku: "", address: "", special_instructions: ""};
      }
    }
  }
  
  addProduct(): void {
    if(!this.special_instructions_list.get(this.productDetail.special_instructions)) {
      this.special_instructions_list.set(this.productDetail.special_instructions, 1)
    } else {
      this.special_instructions_list.set(this.productDetail.special_instructions, this.special_instructions_list.get(this.productDetail.special_instructions) + 1)
    }

    const sortedInstructions = new Map([...this.special_instructions_list.entries()].sort((a, b) => b[1] - a[1]));

    localStorage.setItem("special_instructions", JSON.stringify(Array.from(sortedInstructions.entries())));

    if(!this.orderForItemDisplay.products) {
      this.orderForItemDisplay.products = [
        {
          name: this.productDetail.item.substr(0, this.productDetail.item.indexOf('---')),
          qty: this.productDetail.quantity,
          totals: {
              price_incl_tax: parseFloat((this.productDetail.quantity * (parseFloat((this.productDetail.item.substr(this.productDetail.item.indexOf("---") + 3)))
              + (((this.items.get(this.productDetail.item.substr(0, this.productDetail.item.indexOf('---'))).tax) / 100)
              * parseFloat((this.productDetail.item.substr(this.productDetail.item.indexOf("---") + 3)))))).toFixed(2))
          },
          status: 1,
          parentSku: this.items.get(this.productDetail.item.substr(0, this.productDetail.item.indexOf('---'))).sku,
          address: localStorage.getItem("address").replace(/\"/g,''),
          special_instructions: this.productDetail.item.substr(0, this.productDetail.item.indexOf('---')) + '(' + this.productDetail.special_instructions + ')'
        }
      ];
    } else {
      this.orderForItemDisplay.products.push({
        name: this.productDetail.item.substr(0, this.productDetail.item.indexOf('---')),
        qty: this.productDetail.quantity,
        totals: {
            price_incl_tax: parseFloat((this.productDetail.quantity * (parseFloat((this.productDetail.item.substr(this.productDetail.item.indexOf("---") + 3)))
            + (((this.items.get(this.productDetail.item.substr(0, this.productDetail.item.indexOf('---'))).tax) / 100)
            * parseFloat((this.productDetail.item.substr(this.productDetail.item.indexOf("---") + 3)))))).toFixed(2))
        },
        status: 1,
        parentSku: this.items.get(this.productDetail.item.substr(0, this.productDetail.item.indexOf('---'))).sku,
        address: localStorage.getItem("address").replace(/\"/g,''),
        special_instructions: this.productDetail.item.substr(0, this.productDetail.item.indexOf('---')) + '(' + this.productDetail.special_instructions + ')'
      });
    }
  }

  getItems(items) {
    return Array.from(items.values()); 
  }

  createUpdateOrder(): void {
    this.order.order_type = 'merchant';
  
    for(let i = 0; i < this.orderForItemDisplay.products.length; i++) {
      if(this.orderForItemDisplay.products[i].name !== '') {
        if(!this.order.products) {
          this.order.products = [ this.orderForItemDisplay.products[i] ];
        } else {
          this.order.products.push(this.orderForItemDisplay.products[i]);
        }
      }
    }
    this.order.addressInformation = {
      shippingAddress: {
        country_id: '',
        postcode: '',
        city: '',
        region: '',
        street: ['', '']
      },
      billingAddress: { 
        email: this.productDetail.email,
        telephone: this.productDetail.phone,
        lastname: '',
        firstname: this.productDetail.name,
        country_id: '',
        postcode: '',
        city: '',
        region: '',
        street: ['', '']
      }
    }

    this.order.is_created = true;
    this.order.orderIncrementId = '1';
    this.order.magentoOrderId = '1';
    this.order.transferredAt = '1'; 
    this.order.created_at = Date.now(); 
    this.order.merchant_id = localStorage.getItem("merchant_id").replace(/\"/g,'');
    this.order.cart_id = Md5.hashStr((this.order.addressInformation.billingAddress.telephone + Date.now()).toString()).slice(0, 9);
    this.order.device_id = '1';
    this.order.user_id = '1';

    for(let i = 0; i < this.order.products.length; i++) {
      axios.post('https://beta02.shoponomik.com/eventlog', {
        "order_creation": { 
          "item_name": this.order.products[i].name,
          "item_price": this.items.get(this.order.products[i].name).price,
          "special_instruction": this.order.products[i].special_instructions,
          "merchant_id": localStorage.getItem("merchant_id"), 
          "merchant_address": localStorage.getItem("address"),
          "product_sku": this.items.get(this.order.products[i].name).sku
        },
          "eventType": "mcc-special-instructions",
          "uuid": Md5.hashStr(this.items.get(this.order.products[i].name).sku + this.order.products[i].special_instructions + Date.now())
        },
        { headers: {'Content-Type': 'application/json'}
      });  
    }
    this._orderService.updateOrder(this.order).subscribe(
    () => this.router.navigate(['/order-list/true']))
  }
}
 */