import { Component, OnInit } from '@angular/core';
import {MatDividerModule} from '@angular/material/divider'; 
import { MatTableDataSource } from '@angular/material';
import { ProductsService } from '../products/products.service';
import { Product } from '../products/product';
import { FileUploadService } from '../products/file-upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  displayedColumns: string[] = ['name', 'sku', 'description', 'price', 'stock', 'image', 'edit', 'delete'];
  dataSource = new MatTableDataSource<any>();

  selectedProduct: Product = new Product();

  loading = false;
  progressInfos: any;
  message: any;
  previews: any;
  selectedFiles: any;
  imagefile: File = null; // Variable to store file
 
  imageInfos?: Observable<any>;
  selectedFile: File;
  shortLink: string = "";

  constructor(public productService: ProductsService, private uploadService: FileUploadService) {
  }

  ngOnInit() {
    this.refresh();
    this.imageInfos = this.uploadService.getFiles();
    console.log("imageInfos: " + this.imageInfos._isScalar);
    
  }

  async refresh() {
    this.loading = true;
    const data = await this.productService.getProducts();
    this.dataSource.data = data;
    this.loading = false;
  }

  showFileName(imageName: string){
    document.getElementById('image').textContent = imageName;
  }
  
 onChange(event: any) {

    this.selectedProduct.image = (event.target as HTMLInputElement).files[0];
    //this.selectedProduct.image = 'bigsur.jpeg';
    console.log('--selectedProduct.image : ' + this.selectedProduct.image);
    console.log(`--selectedProduct.image.name : ${this.selectedProduct.image.name}`);
    
    //this.onUpload();
  }

  async onUpload() {
    this.loading = !this.loading;
    console.log("this.selectedProduct.image: "+this.selectedProduct.image);
    (await this.uploadService.upload(this.selectedProduct.image, this.selectedProduct)).subscribe(
        (event: any) => {
            if (typeof (event) === 'object') {

                // Short link via api response
                //this.shortLink = event.link;
                console.log("Event Link: "+ event.link); //this.shortLink);

                this.loading = false; // Flag variable 

                this.imageInfos = this.uploadService.getFiles();
                //this.showFileName(this.selectedProduct.image.name);
            }
        }
    );
}
  
  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFile = event.target.files[0];

    console.log("selected file: " + this.selectedFile);

    this.previews = [];

    const reader = new FileReader();

    reader.onload = (e: any) => {
      console.log("from inside selectFiles() "+ e.target.result);
      this.previews.push(e.target.result);
    };

    reader.readAsDataURL(this.selectedFile);

    console.log("selectedFile after readAsDataUrl" + this.selectedFile);

    this.upload(1, this.selectedFile);
  }

  async upload(idx: number, file: File): Promise<void> {
    //this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      (await this.uploadService.upload(file, this.selectedProduct)).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            const msg = 'Uploaded the file successfully: ' + file.name;
            this.message.push(msg);
            this.imageInfos = this.uploadService.getFiles();
          }
        },
        error: (err: any) => {
          //this.progressInfos[idx].value = 0;
          const msg = 'Could not upload the file: ' + file.name;
          this.message.push(msg);
        }});
    }
  }

  async updateProduct() {
    if (this.selectedProduct.id !== undefined) {
      await this.productService.updateProduct(this.selectedProduct, this.selectedProduct.image);
    } else {
      await this.productService.createProduct(this.selectedProduct, this.selectedProduct.image);
    }
    this.selectedProduct = new Product();
    window.location.reload();
    await this.refresh();
  }

  editProduct(product: Product) {
    this.selectedProduct = product;
  }

  clearProduct() {
    this.selectedProduct = new Product();
  }

  async deleteProduct(product: Product) {
    this.loading = true;
    if (confirm(`Are you sure you want to delete the product ${product.name}. This cannot be undone.`)) {
      await this.productService.deleteProduct(`${product.id}`);
    }
    window.location.reload();
    await this.refresh();
  }
}
