import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './products/products.component';
import { HomeComponent } from './home/home.component';
import { CardDetailsComponent } from './card-details/card-details.component';
import { FormsModule } from '@angular/forms';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { CreateUpdateOrderComponent } from './create-update-order/create-update-order.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrdersComponent } from './orders/orders.component';
import { SignInComponent } from './auth/components/sign-in/sign-in.component';


const routes: Routes = [
  /* {path: 'home', component: HomeComponent}, // IN USE
  {path: 'products', component: ProductsComponent}, //IN USE
  {path: 'card-details', component: CardDetailsComponent}, //IN USE
  {path: 'order-list', component: AllOrdersComponent},
  {path: 'order-list/:modifiable', component: AllOrdersComponent},
  //{path: 'callback', component: OktaCallbackComponent},
  //{path: 'update-order/:id', component: CreateUpdateOrderComponent},
  {path: 'order/:id', component: OrderDetailsComponent},
  {path: 'order', component: CreateUpdateOrderComponent},
  {path: 'orders', component: OrdersComponent}, //IN USE
  {path: 'auth', component: SignInComponent}, */
 // { path: '', component: HomeComponent },
  { path: 'all-products', component: ProductsComponent },
  { path: 'all-orders', component: AllOrdersComponent },
  { path: 'order/:cart_id', component: OrderDetailsComponent },
  { path: 'order', component: CreateUpdateOrderComponent },
  { path: 'auth', component: SignInComponent },
  { path: 'home', component: HomeComponent },
  { path: 'card-details', component: CardDetailsComponent },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' } 

]


@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    FormsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
