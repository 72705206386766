import { Injectable} from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from './product';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private baseUrl = environment.modules.auth.basic.baseUrl //'http://localhost:4201';

  constructor(private httpL: HttpClient) { 
  }

  // Function to upload text file (.csv)
  uploadTextFile(file: File):Observable<any> {
    let merchantId = localStorage.getItem('merchant_id');

    // Create form data
    let formData = new FormData(); 

    formData.append("product_catalog", file, file.name) //+ `_${merchantId}` + `_${Date.now}`);
    formData.append('merchantId', merchantId);

    //const req = new HttpRequest('POST', `${BASE_URL}${url}`, formData, options)
    const req = new HttpRequest('POST', `${this.baseUrl}/upload1`, formData);
    return this.httpL.request(req);
  } 

   // Returns an observable
  upload(file: File, product: Product):Observable<any> {
  
      // Create form data
      let formData = new FormData(); 
        
      // Store form name as "file" with file data
      console.log('file.length = ' + file.size)
      console.log('product.sku = ' + product.sku);
      let merchantName = 'jenin-furnishing'
      console.log('jenin-furnishing ' + merchantName);
      
      // let fs = require('fs');
      //formData.append('file', file, file.name)
      if (product.merchantId === ('BVyhHPDnS0jiaAGsAUiF+xXJaFLuhKKTu6Zx4Ls5wAQ=')) {
         merchantName = 'jenin-furnishing'
      }

      //formData.append('image', file, merchantName + '--' + product.sku)
      formData.append("image", file, `${product.sku}.jpeg`);
      formData.append('merchantId', product.merchantId)
      //`${product.sku}.jpeg`);
      //formData.append('file', file);
      //formData.append("sku", product.sku);
      console.log("--formData: " + formData);
      console.log("--file : " + file);
      console.log("--file name : " + file.name);
      console.log("--file type : " + file.type);

      //formData.get(Headers)
        
      // Make http post request over api
      // with formData as req
      //return  uploadResponse = await fetch(`${this.baseUrl}/uploads`, {method: 'POST', body: formData });
      // this.http.post(`${this.baseUrl}/uploads`, {body:formData}, {headers: {"Content-Type": "multipart/form-data", 'Content-Length': `$(file.length)`}});
      //return this.http.post(`${this.baseUrl}/uploads`, {body:formData}, {headers: {"Content-Type": "multipart/form-data"}});
      //return this.http.post(`${this.baseUrl}/uploads`, formData);
     // const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      let headers = new Headers({'Content-Type': 'multipart/form-data'});
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data' //     <-- IMPORTANT
        })
      };
      //let options = new RequestOptions({headers: headers});
      //return this.http.post(`${this.baseUrl}/uploads`, formData, httpOptions);
      let MyBoundary='name=Abebe&age=5'
      const options2 = {};
      const options = {
      headers: new HttpHeaders({'Content-Type':'multipart/form-data; boundary=--boundaryXXX--',
        'enctype': 'multipart/form-data'}), // 'Boundary':'--boundaryXXX--'}),
      //'Content-Type': 'application/x-www-form-urlencoded' 'Content-Type':'application/json; charset=utf-8',
      //mime-type': 'image/png',
      //Content-Type': 'multipart/form-data',
      //headers: new HttpHeaders({ 'Content-Type': 'none'}),
 
        params: new HttpParams(),
         reportProgress: false,
    };

     let body = {formData};

 //const req = new HttpRequest('POST', `${BASE_URL}${url}`, formData, options)
      const req = new HttpRequest('POST', `${this.baseUrl}/uploads`, formData, options2);
      return this.httpL.request(req);
      //(`${this.baseUrl}/uploads`, JSON.stringify(formData), {headers: {'Content-Type': 'multipart/form-data', 'enctype': 'multipart/form-data'}});
      ;
      //return new Observable(jttj(res=>{
      //do stuff
     // }));
            //return this.http.post(`${this.baseUrl}/uploads`, formData);

      //let fd = new FormData();
      //fd.append('file',files[0])
       /*    return Axios.post(`${this.baseUrl}/uploads`, {body: formData})
      .subscribe(
        response => console.log(response),
        error => console.log(error)
      ); */
  }

  getFiles(): Observable<any> {
    console.log('FileUploadService ' + 'this.getFiles()')
    const options = {
      headers: new HttpHeaders({'Content-Type':'image/jpeg'}), // 'Boundary':'--boundaryXXX--'}),
      //'Content-Type': 'application/x-www-form-urlencoded' 'Content-Type':'application/json; charset=utf-8',
      //mime-type': 'image/png',
      //Content-Type': 'multipart/form-data',
      //headers: new HttpHeaders({ 'Content-Type': 'none'}),
 
        params: new HttpParams(),
         reportProgress: false,
    };
    return this.httpL.get(`${this.baseUrl}/uploads-all`, options);
  }
}



