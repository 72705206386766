import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProductsService } from './products.service';
import { Product } from './product';
import { FileUploadService } from './file-upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})

export class ProductsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'sku', 'description', 'price', 'stock', 'relatedSku', 'addons', 'size' , 'color', 'additionalNotes', 'image', 'edit', 'delete'];
  dataSource = new MatTableDataSource<any>();

  selectedProduct: Product;
  public uploadUrl = "http://localhost:4201/uploads";

  loading = false;
  progressInfos: any;
  message: any;
  previews: any;
  selectedFiles: any;
  imagefile: File = null; // Variable to store file
  product_catalog: File = null; // Variable to store .csv file
  imageInfos?: Observable<any>;
  selectedFile: File;
  shortLink: string = "";

  constructor(public productService: ProductsService, private uploadService: FileUploadService) {
  }

  @ViewChild('paginator') paginator: MatPaginator;

  ngOnInit() {
    this.refresh();
    this.selectedProduct = new Product();
    this.imageInfos = this.uploadService.getFiles();
    this.merchantId(); //get merchant_id at init
    this.dataSource.paginator = this.paginator;
    console.log("imageInfos: " + this.imageInfos._isScalar);
  }

  async refresh() {
    this.loading = true;
    const data = await this.productService.getProducts();
    this.dataSource.data = data;
    this.loading = false;
  }

  async merchantId() {
    this.loading = true;
    const merchantId = await this.productService.getMerchantId(); //"BVyhHPDnS0jiaAGsAUiF+xXJaFLuhKKTu6Zx4Ls5wAQ="//;
    var merchantIdJSON = JSON.parse(JSON.stringify(merchantId));
    const merchantIdValue = merchantIdJSON['merchantId'];
    this.selectedProduct.merchantId = localStorage.getItem("merchant_id") //merchantIdValue;//"BVyhHPDnS0jiaAGsAUiF+xXJaFLuhKKTu6Zx4Ls5wAQ=" //
    console.log('this.selectedProduct.merchantId  from web= ' + this.selectedProduct.merchantId  + ' merchantIdValue from localstorage=' + merchantIdValue) 
    this.loading = false;
  }

  showFileName(imageName: string){
    document.getElementById('image').textContent = imageName;
  }
  
 onChange(event: any) {
  if(this.selectedProduct.image !== null) {
    this.selectedProduct.image = (event.target as HTMLInputElement).files[0];
    //this.selectedProduct.image = 'bigsur.jpeg';
    //console.log('selectedProduct.image : ' + this.selectedProduct.image);
    //console.log(`selectedProduct.image.name : ${this.selectedProduct.image.name}`);
  } else {
    this.product_catalog = (event.target as HTMLInputElement).files[0];
  }
 
  this.onUpload();
}

  onChangeTextFile(event: any) {
    this.product_catalog = (event.target as HTMLInputElement).files[0];
    this.onUploadTextFile();
  }

  onUploadTextFile() {
    this.loading = !this.loading;

    (this.uploadService.uploadTextFile(this.product_catalog)).subscribe(
      (event: any) => {
          if (typeof (event) === 'object') {

              // Short link via api response
              //this.shortLink = event.link;
              console.log("Event Link: "+ event.link); //this.shortLink);

              this.loading = false; // Flag variable 

              //this.imageInfos = this.uploadService.getFiles();
              //this.showFileName(this.selectedProduct.image.name);
          }
      }
    );
  }

  onUpload() {
    this.loading = !this.loading;
    //console.log("this.selectedProduct.image: "+ this.selectedProduct.image);
    if(this.selectedProduct.image !== null) {
      (this.uploadService.upload(this.selectedProduct.image, this.selectedProduct)).subscribe(
        (event: any) => {
            if (typeof (event) === 'object') {

                // Short link via api response
                //this.shortLink = event.link;
                console.log("Event Link: "+ event.link); //this.shortLink);

                this.loading = false; // Flag variable 

                this.imageInfos = this.uploadService.getFiles();
                //this.showFileName(this.selectedProduct.image.name);
            }
        }
      );
    } else {
      (this.uploadService.uploadTextFile(this.product_catalog)).subscribe(
        (event: any) => {
            if (typeof (event) === 'object') {

                // Short link via api response
                //this.shortLink = event.link;
                console.log("Event Link: "+ event.link); //this.shortLink);

                this.loading = false; // Flag variable 

                //this.imageInfos = this.uploadService.getFiles();
                //this.showFileName(this.selectedProduct.image.name);
            }
        }
    );
    }
    
}
  
  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFile = event.target.files[0];

    console.log("selected file: " + this.selectedFile);

    this.previews = [];

    const reader = new FileReader();

    reader.onload = (e: any) => {
      console.log("from inside selectFiles() "+ e.target.result);
      this.previews.push(e.target.result);
    };

    reader.readAsDataURL(this.selectedFile);

    console.log("selectedFile after readAsDataUrl" + this.selectedFile);

    this.upload(1, this.selectedFile);
  }

  upload(idx: number, file: File): void {
    //this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      this.uploadService.upload(file, this.selectedProduct).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            const msg = 'Uploaded the file successfully: ' + file.name;
            this.message.push(msg);
            this.imageInfos = this.uploadService.getFiles();
          }
        },
        error: (err: any) => {
          //this.progressInfos[idx].value = 0;
          const msg = 'Could not upload the file: ' + file.name;
          this.message.push(msg);
        }});
    }
  }

  async updateProduct() {
    this.selectedProduct.merchantId= localStorage.getItem('merchant_id')

    if (this.selectedProduct.id !== undefined && this.selectedProduct.merchantId !== undefined)  {
      console.log(JSON.stringify('updateProduct' + this.selectedProduct))
       //'BVyhHPDnS0jiaAGsAUiF+xXJaFLuhKKTu6Zx4Ls5wAQ='
      await this.productService.updateProduct(this.selectedProduct, this.selectedProduct.image);
    } else {
      //'BVyhHPDnS0jiaAGsAUiF+xXJaFLuhKKTu6Zx4Ls5wAQ='
      await this.productService.createProduct(this.selectedProduct, this.selectedProduct.image);
    }
    this.selectedProduct = new Product();
    await this.refresh();
    //window.location.reload();
    
  }

  async uploadProductCatalog() {
    this.selectedProduct.merchantId= 'BVyhHPDnS0jiaAGsAUiF+xXJaFLuhKKTu6Zx4Ls5wAQ='
    //a new function in service file to upload the csv
    await this.productService.createProduct(this.selectedProduct, this.selectedProduct.image);
  }

  editProduct(product: Product) {
    this.selectedProduct = product;
  }

  clearProduct() {
    this.selectedProduct = new Product();
  }

  async deleteProduct(product: Product) {
    this.loading = true;
    if (confirm(`Are you sure you want to delete the product ${product.name}. This cannot be undone.`)) {
      await this.productService.deleteProduct(`${product.id}`);
    }
    //window.location.reload();
    this.refresh();
  }
}
