import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { Order, OrderOms } from './order';

@Injectable()
export class OrderService {

  //private _apiPath = 'api/orders';
  private _apiPath = /* 'http://localhost:8081/api/v1/' */ 'https://www.shoponomik.com/api/v1/'  //'https://beta02.shoponomik.com/api/v1/'
  public ordersOms: Map<string, OrderOms> = new Map();
  constructor(private _http: HttpClient) { }

  getOrder(id: string): Observable<Order> {
    const url = `${this._apiPath}/${id}`;
    return this._http.get<Order>(url);
  }

  createOrder(order: any): Observable<Order> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //order.orders.KG37aDZo4YgbECL1FrY2amaDr5sdd50D.order.cart_id = null;
    console.log("New order created successfuly: " + JSON.stringify(order));
    return this._http.post<Order>(this._apiPath, order, { headers: headers });
  }

  getItems(restaurant_name: string): Observable<any> {
    return this._http.get<any>(this._apiPath + "get-products?categoryName=" + restaurant_name + "&fields=name,sku,price,status,tax");
  }

  getOrders(order: Order): Observable<Order[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //order.id = null;
    //const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(order);
    console.log(body)
    return this._http.post<Order[]>(this._apiPath, body, { headers: headers }, );
  }

  getOrdersByMerchant(merchant_id: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //order.id = null;
    //const headers = { 'content-type': 'application/json'} 
    const body = {
      "merchant_id": merchant_id
    };
    console.log(merchant_id);
    return this._http.post<any>(this._apiPath + "orders_merchant", body, { headers: headers});
  }

  deleteOrder(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this._apiPath}/${id}`;
    return this._http.delete<Order>(url, { headers: headers });
  }

  updateOrder(orderPayload: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this._apiPath + "updateorder";
    console.log(orderPayload); 
    return this._http.post<any>(url, { orderPayload }, { headers: headers });
  }
}
